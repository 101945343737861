<template>
  <v-row v-if="iMovementType === 13" class="align-center">
    <v-col cols="12" lg="3" sm="4">
      <sell-clause-select
        :value="sSellClause"
        required
        @change="onSelectSellClause"
      />
    </v-col>
    <v-col cols="12" lg="3" sm="4">
      <sell-mode-select
        :value="sSellMode"
        required
        @change="onSelectSellMode"
      />
    </v-col>
    <v-col cols="12" lg="3" sm="4">
      <transport-select
        :value="sTransport"
        required
        @change="onSelectTransport"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import SellClauseSelect from "@/modules/invoices/components/export/SellClauseSelect.vue";
import { SellClause, SellMode, Transport } from "@/services/Utils";
import SellModeSelect from "@/modules/invoices/components/export/SellModeSelect.vue";
import TransportSelect from "@/modules/invoices/components/export/TransportSelect.vue";

@Component({
  components: { TransportSelect, SellModeSelect, SellClauseSelect },
})
export default class ExportFields extends Mixins(InvoiceMixin) {
  get sSellClause() {
    return this.obInvoice.get("sell_clause") as SellClause;
  }

  get sSellMode() {
    return this.obInvoice.get("sell_mode") as SellMode;
  }

  get sTransport() {
    return this.obInvoice.get("transport") as Transport;
  }

  onSelectSellClause(sValue: SellClause) {
    this.obInvoice.set("sell_clause", sValue);
  }

  onSelectSellMode(sValue: SellMode) {
    this.obInvoice.set("sell_mode", sValue);
  }

  onSelectTransport(sValue: Transport) {
    this.obInvoice.set("transport", sValue);
  }
}
</script>

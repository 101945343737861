<template>
  <ValidationProvider
    :name="$t('invoice.movement.type')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicemovementtype"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="invoiceMovementTypeId"
        :clearable="clearable"
        :error-messages="errors"
        :items="items"
        :label="$t('invoice.movement.type')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :multiple="multiple"
        :success="valid"
        dense
        hide-details
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onSelect"
        @click:clear="onCleanSelection"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import {
  InvoiceGroup,
  InvoiceGroupData,
  InvoiceMovementTypeCollection,
  InvoiceMovementTypeData,
  InvoiceTypeData,
} from "@planetadeleste/vue-mc-gw";
import {
  castArray,
  filter,
  find,
  get,
  intersection,
  isUndefined,
  map,
} from "lodash";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { ConfigModule } from "@/store/config";
import { number } from "mathjs";
import useInvoiceMovementType, {
  InvoiceMovementTypeHelper,
} from "@/composables/invoiceMovementType";
import { InvoiceMovementTypeCode } from "@/types/utils";

@Component
export default class InvoiceMovementTypeSelect extends Vue {
  @VModel({ type: [Number, Array] }) invoiceMovementTypeId!: number | number[];
  @Prop(Number) readonly invoiceGroupId!: number;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Boolean) readonly multiple!: boolean;
  @Prop(Boolean) readonly noAutoassign!: boolean;
  @Prop(Boolean) readonly clearable!: boolean;

  /**
   * Validate from available company invoice types
   */
  @Prop(Boolean) readonly validateCompany!: boolean;
  @Prop(Object) readonly invoiceGroup!: InvoiceGroupData;

  /**
   * Filter by invoice movement type codes
   */
  @Prop([Array, String, Number]) readonly movementTypeCode!:
    | InvoiceMovementTypeCode
    | InvoiceMovementTypeCode[];

  /**
   * Filter by invoice type codes
   */
  @Prop([Array, String, Number]) readonly invoiceTypeCode!: number | number[];

  obCollection: InvoiceMovementTypeCollection =
    new InvoiceMovementTypeCollection();
  obInvoiceGroup!: InvoiceGroup;
  loading = false;
  obMovementTypeHelper: InvoiceMovementTypeHelper | null = null;

  get items() {
    const arModels = this.obMovementTypeHelper
      ? this.obMovementTypeHelper.items
      : [];

    return filter(arModels, this.validate);
  }

  get companyInvoiceTypeCodeList(): number[] {
    return map(ConfigModule.companyInvoiceTypes, (obModel) =>
      number(obModel.code)
    );
  }

  get arMovementTypeCode(): InvoiceMovementTypeCode[] {
    if (!this.movementTypeCode) {
      return [];
    }

    const arCodes = filter(
      castArray(this.movementTypeCode),
      (sValue) => !isUndefined(sValue)
    );

    return arCodes.length
      ? (map(arCodes, (sValue: string | number) => {
          return number(sValue);
        }) as InvoiceMovementTypeCode[])
      : [];
  }

  get arInvoiceTypeCode(): any[] | number[] {
    if (!this.invoiceTypeCode) {
      return [];
    }

    return map<number>(
      castArray(this.invoiceTypeCode),
      (sValue: string | number) => number(sValue)
    ) as unknown as number[];
  }

  @Watch("invoiceGroupId")
  async onChangeInvoiceGroup(iInvoiceGroupId: number) {
    if (iInvoiceGroupId) {
      this.obInvoiceGroup = new InvoiceGroup({ id: iInvoiceGroupId });
      await this.obInvoiceGroup.fetch();
    }
  }

  @Watch("items")
  onChangeItems() {
    this.autoAssign();
  }

  @Watch("value")
  onChangeValue() {
    this.autoAssign();
  }

  created() {
    useInvoiceMovementType().then((obObject) => {
      this.obMovementTypeHelper = obObject.obj;
      this.autoAssign();
    });
  }

  async mounted() {
    this.$nextTick(this.autoAssign);
  }

  onCleanSelection() {
    this.$emit("change", null);
    this.$emit("input", null);
  }

  onSelect(fValue: number) {
    this.$emit("change", find(this.items, { id: fValue }));
  }

  validate(obModel: InvoiceMovementTypeData) {
    const obInvoiceGroup = this.invoiceGroup || this.obInvoiceGroup;
    const arInvoiceType: InvoiceTypeData[] = get(obModel, "invoice_types", []);
    const sCode = number(obModel.code);

    // Validate from company settings
    if (this.validateCompany) {
      if (!this.companyInvoiceTypeCodeList.length) {
        return false;
      }

      // Validate by movement type code in props
      if (
        this.arMovementTypeCode.length &&
        !this.arMovementTypeCode.includes(sCode)
      ) {
        return false;
      }

      let arCodeList: InvoiceMovementTypeCode[] = map(arInvoiceType, (obItem) =>
        number(obItem.code)
      );

      if (this.arInvoiceTypeCode.length) {
        arCodeList = intersection(arCodeList, this.arInvoiceTypeCode);
      }

      return (
        arCodeList.length > 0 &&
        intersection(this.companyInvoiceTypeCodeList, arCodeList).length > 0
      );
    }

    if (this.arMovementTypeCode.length) {
      return this.arMovementTypeCode.includes(sCode);
    }

    if (!obInvoiceGroup) {
      return ![3, 4].includes(sCode);
    }

    return obInvoiceGroup.code !== "2" ? sCode !== 4 : true;
  }

  autoAssign() {
    if (!this.items.length || this.noAutoassign) {
      return;
    }

    const arIdList = map(this.items, "id") as number[];

    if (
      !this.multiple &&
      this.invoiceMovementTypeId &&
      arIdList.includes(this.invoiceMovementTypeId as number)
    ) {
      this.onSelect(this.invoiceMovementTypeId as number);
      return;
    }

    const arCodeList = map(this.items, "code") as string[];
    const sCode = arCodeList.includes("1") ? "1" : arCodeList[0];
    const obInvoiceMovementType = find(this.items, { code: sCode }) as
      | InvoiceMovementTypeData
      | undefined;
    if (obInvoiceMovementType) {
      this.$emit("input", obInvoiceMovementType.id);
      this.onSelect(obInvoiceMovementType.id);
    }
  }
}
</script>

<template>
  <gw-dialog>
    <template #activator="{ on }">
      <v-btn v-on="on" color="primary" icon>
        <icon-eye-outline />
      </v-btn>
    </template>

    <firm-preview :item="item" />
  </gw-dialog>
</template>

<script lang="ts">
import { FirmData } from "@planetadeleste/vue-mc-gw";
import { Component, Prop, Vue } from "vue-property-decorator";

import FirmPreview from "@/modules/companies/components/FirmPreview.vue";
import GwDialog from "@/components/common/GwDialog.vue";

@Component({
  components: { FirmPreview, GwDialog },
})
export default class FirmPreviewDialog extends Vue {
  @Prop(Object) readonly item!: Partial<FirmData>;
  dialog = false;
}
</script>

<style scoped></style>

<template>
  <ValidationProvider
    :name="$t('invoice.transport')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicesellmode"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="sValue"
        :error-messages="errors"
        :items="arItems"
        :label="$t('invoice.transport')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        hide-details
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Utils, { Transport } from "@/services/Utils";
import { map } from "lodash";

@Component
export default class TransportSelect extends Vue {
  @VModel({ type: [String, Number] }) sValue!: Transport;
  @Prop(Boolean) readonly required!: boolean;

  loading = false;
  arItems: Record<string, any>[] = [];

  async mounted() {
    const arModeList = await Utils.getTransport();
    this.arItems = map(arModeList, (sValue) => {
      return { text: this.$t(`invoice.transport.${sValue}`), value: sValue };
    });
  }

  onSelect(sValue: string) {
    this.$emit("change", sValue);
  }
}
</script>

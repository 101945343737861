<template>
  <ValidationProvider
    :name="$t('invoice.sell.clause')"
    :rules="required ? 'required' : ''"
    slim
    vid="invoicesellclause"
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="sValue"
        :error-messages="errors"
        :items="arItems"
        :label="$t('invoice.sell.clause')"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="valid"
        dense
        hide-details
        outlined
        v-bind="$attrs"
        @change="onSelect"
      />
    </template>
  </ValidationProvider>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import Utils, { SellClause } from "@/services/Utils";

@Component
export default class SellClauseSelect extends Vue {
  @VModel({ type: String }) sValue!: string;
  @Prop(Boolean) readonly required!: boolean;

  loading = false;
  arItems: SellClause[] = [];

  async mounted() {
    this.arItems = await Utils.getSellClause();
  }

  onSelect(sValue: string) {
    this.$emit("change", sValue);
  }
}
</script>
